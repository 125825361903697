import { ClassNames } from "@emotion/core";
import { TreeSelect } from "antd";
import React from "react";

export default (props) => (
  <ClassNames>
    {({ css }) => (
      <TreeSelect
        css={{
          ".ant-select-selection-item": { width: 0 }, // for ellipsis to work
        }}
        dropdownClassName={css({
          ".ant-select-tree-node-content-wrapper:not(.ant-select-tree-node-content-wrapper-normal)": {
            pointerEvents: "none",
          },
        })}
        {...props}
      />
    )}
  </ClassNames>
);
