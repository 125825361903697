import React, { createContext, useContext, useMemo, useState } from "react";
import tinycolor from "tinycolor2";
import {
  CloseOutlined,
  InfoCircleOutlined,
  PlusOutlined,
  ThunderboltOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,
  Form,
  InputNumber,
  List,
  Row,
  Select,
  Tooltip,
} from "antd";
import { entries, get, groupBy, set } from "lodash";
import produce from "immer";
import useTranslation from "../lang/useTranslation";
import useSubmitState from "./useSubmitState";
import PlaceInput from "./PlaceInput";
import { mY } from "./spacingShorthands";
import { green } from "../theme/colors";
import {
  categories,
  transports,
  materials,
} from "../../data/data/footprints-client.json";
import TreeSelect from "./TreeSelect";

const FormContext = createContext();

const FormItem = ({ required, rules = [], ...props }) => {
  const t = useTranslation();
  return (
    <Form.Item
      rules={
        required
          ? [{ required: true, message: t("required") }, ...rules]
          : rules
      }
      {...props}
    />
  );
};

const categoryOptions = Object.keys(categories).map((k) => ({
  label: k,
  value: k,
}));

const pathsToTree = (paths, level = 1) =>
  entries(groupBy(paths, (p) => p.split("|").slice(0, level).join("|")))
    .map(([value, children]) =>
      children.length > 1
        ? {
            value,
            title: value.split("|").slice(-1)[0],
            selectable: false,
            children: pathsToTree(children, level + 1),
          }
        : {
            value,
            title: value.split("|").slice(-1)[0],
            selectedTitle: value.split("|").join(" - "),
            isLeaf: true,
          }
    )
    .sort(({ title: t1 }, { title: t2 }) => {
      if (t1 === "Average") return -1;
      if (t2 === "Average") return 1;
      if (t1 === "Miscellaneous") return 1;
      if (t2 === "Miscellaneous") return -1;
      return t1.localeCompare(t2);
    });

const transportsTree = pathsToTree(transports);
const materialsTree = pathsToTree(materials);

export const Parts = ({ name, fullName, label }) => {
  const { isSubmitting, form } = useContext(FormContext);
  return (
    <div css={{ marginTop: 48 }}>
      <Divider>{label}</Divider>
      <Form.List shouldUpdate name={name} css={mY(48)}>
        {(fields, { add, remove }) => (
          <List
            size="small"
            bordered
            footer={
              <div css={{ textAlign: "center" }}>
                <Button
                  disabled={isSubmitting}
                  icon={<PlusOutlined />}
                  onClick={() => add()}
                >
                  Add component
                </Button>
              </div>
            }
            dataSource={fields}
            renderItem={({ name: i }) => (
              <List.Item
                css={{
                  paddingLeft: "8px !important",
                  paddingRight: "8px !important",
                  flexDirection: "row-reverse",
                  flexWrap: "nowrap !important",
                  ".ant-list-item-action": {
                    marginLeft: 0,
                    alignSelf: "stretch",
                    marginRight: 8,
                    borderRight: "1px solid #f0f0f0",
                  },
                }}
                actions={[
                  <Button
                    css={{ marginTop: 12 }}
                    disabled={isSubmitting}
                    key="delete"
                    type="link"
                    icon={<CloseOutlined />}
                    onClick={() => remove(i)}
                  />,
                ]}
              >
                <div css={{ flexGrow: 1 }}>
                  <Row gutter={16} css={{ marginBottom: -16, marginTop: 8 }}>
                    <Col xs={24} md={{ span: 8, offset: 8 }}>
                      <FormItem name={[i, "type"]} label="Type" required>
                        <Select
                          disabled={isSubmitting}
                          options={[
                            { label: "Raw material", value: "rawMaterial" },
                            {
                              label: "Custom processed product",
                              value: "processedProduct",
                            },
                          ]}
                          onChange={
                            () => form.setFieldsValue({}) // trigger rerender
                          }
                        />
                      </FormItem>
                    </Col>
                  </Row>
                  {form.getFieldValue([...fullName, i, "type"]) ===
                    "processedProduct" && (
                    <Content fullBaseName={[...fullName, i]} baseName={[i]} />
                  )}
                  {form.getFieldValue([...fullName, i, "type"]) ===
                    "rawMaterial" && (
                    <Row gutter={16} css={{ marginTop: 16, marginBottom: -8 }}>
                      <Col xs={24} sm={12}>
                        <FormItem
                          label="Material"
                          name={[i, "material"]}
                          required
                        >
                          <TreeSelect
                            treeNodeLabelProp="selectedTitle"
                            disabled={isSubmitting}
                            treeData={materialsTree}
                          />
                        </FormItem>
                      </Col>
                      <Col xs={24} sm={12}>
                        <FormItem
                          name={[i, "weight"]}
                          label="Weight (kg)"
                          required
                        >
                          <InputNumber
                            css={{ width: "100%" }}
                            min={0}
                            disabled={isSubmitting}
                          />
                        </FormItem>
                      </Col>
                      <Col xs={24} sm={12}>
                        <FormItem name={[i, "origin"]} label="Origin" required>
                          <PlaceInput disabled={isSubmitting} />
                        </FormItem>
                      </Col>
                      <Col xs={24} sm={12}>
                        <FormItem
                          name={[i, "supplyMode"]}
                          label="Supply Mode"
                          required
                        >
                          <TreeSelect
                            disabled={isSubmitting}
                            treeNodeLabelProp="selectedTitle"
                            treeData={transportsTree}
                          />
                        </FormItem>
                      </Col>
                    </Row>
                  )}
                </div>
              </List.Item>
            )}
          />
        )}
      </Form.List>
    </div>
  );
};

const roundTo = (x, n) => Math.round(x * 10 ** n) / 10 ** n;

const Content = ({ baseName = [], fullBaseName = [] }) => {
  const { form, isSubmitting } = useContext(FormContext);
  const [touchedElectricity, setTouchedElectricity] = useState(false);
  return (
    <div
      css={{
        padding: fullBaseName.length ? 8 : 0,
        backgroundColor: tinycolor("#fff")
          .darken(fullBaseName.length)
          .toHexString(),
      }}
    >
      <Divider>Manufacturing & Distribution</Divider>
      <Row gutter={16} css={{ marginBottom: -24 }}>
        <Col xs={24} sm={12} md={8}>
          <FormItem name={[...baseName, "weight"]} label="Weight (kg)" required>
            <InputNumber
              css={{ width: "100%" }}
              autoFocus={!!fullBaseName.length}
              min={0}
              disabled={isSubmitting}
              onChange={(w) => {
                if (
                  !touchedElectricity &&
                  form.getFieldValue([...fullBaseName, "category"])
                )
                  form.setFieldsValue(
                    produce(form.getFieldsValue(), (draft) => {
                      set(
                        draft,
                        [...fullBaseName, "electricity"],
                        roundTo(
                          categories[
                            get(draft, [...fullBaseName, "category"])
                          ] * (Number(w) || 1),
                          3
                        )
                      );
                    })
                  );
              }}
            />
          </FormItem>
        </Col>
        <Col xs={24} sm={12} md={8}>
          <FormItem
            name={[...baseName, "factoryLocation"]}
            label="Factory Location"
            required
          >
            <PlaceInput disabled={isSubmitting} />
          </FormItem>
        </Col>
        <Col xs={24} sm={12} md={8}>
          <FormItem
            name={[...baseName, "category"]}
            label={
              <Tooltip
                color={green}
                trigger={["hover", "click"]}
                title="This will give you an estimate for the energy used / product (in the next box)"
              >
                Category <InfoCircleOutlined css={{ color: green }} />
              </Tooltip>
            }
            required
          >
            <Select
              disabled={isSubmitting}
              options={categoryOptions}
              onChange={(c) => {
                if (!touchedElectricity)
                  form.setFieldsValue(
                    produce(form.getFieldsValue(), (draft) => {
                      set(
                        draft,
                        [...fullBaseName, "electricity"],
                        roundTo(
                          categories[c] *
                            (get(draft, [...fullBaseName, "weight"]) || 1),
                          3
                        )
                      );
                    })
                  );
              }}
            />
          </FormItem>
        </Col>
        <Col xs={24} sm={12} md={8}>
          <FormItem
            name={[...baseName, "electricity"]}
            label={
              <Tooltip
                color={green}
                trigger={["hover", "click"]}
                title='If you don&apos;t know it, use the "Category" box (the previous one) to get an estimate here'
              >
                Electricity used (kWh/product){" "}
                <InfoCircleOutlined css={{ color: green }} />
              </Tooltip>
            }
            required
          >
            <InputNumber
              css={{ width: "100%" }}
              min={0}
              disabled={isSubmitting}
              onChange={(v) => setTouchedElectricity(!!v)}
            />
          </FormItem>
        </Col>
        {!fullBaseName.length && (
          <Col xs={24} sm={12} md={8}>
            <FormItem
              name={[...baseName, "distributionLocation"]}
              label="Distribution Location"
              required
            >
              <PlaceInput disabled={isSubmitting} />
            </FormItem>
          </Col>
        )}
        <Col xs={24} sm={12} md={8}>
          <FormItem
            name={[...baseName, "distributionMode"]}
            label="Distribution Mode"
            required
          >
            <TreeSelect
              disabled={isSubmitting}
              treeNodeLabelProp="selectedTitle"
              treeData={transportsTree}
            />
          </FormItem>
        </Col>
      </Row>
      <Parts
        name={[...baseName, "components"]}
        fullName={[...fullBaseName, "components"]}
        label="Components"
        isSubmitting={isSubmitting}
      />
      <Parts
        name={[...baseName, "packaging"]}
        fullName={[...fullBaseName, "packaging"]}
        label="Packaging"
        isSubmitting={isSubmitting}
      />
    </div>
  );
};

export default ({ onSubmit, ...props }) => {
  const [isSubmitting, submit] = useSubmitState(onSubmit);
  const [form] = Form.useForm();

  return (
    <FormContext.Provider
      value={useMemo(() => ({ form, isSubmitting }), [form, isSubmitting])}
    >
      <Form form={form} layout="vertical" onFinish={submit} {...props}>
        <Content />
        <FormItem css={{ textAlign: "center", marginTop: 48 }}>
          <Button
            size="large"
            type="primary"
            htmlType="submit"
            loading={isSubmitting}
            icon={<ThunderboltOutlined />}
          >
            Calculate
          </Button>
        </FormItem>
      </Form>
    </FormContext.Provider>
  );
};
