import React, { useEffect, useRef, useState } from "react";
import { Input } from "antd";
import useScript from "./useScript";
import "./googleMaps.css";

export default ({ value: { name } = {}, onChange, ...props }) => {
  const Autocomplete = useScript({
    src: `https://maps.googleapis.com/maps/api/js?key=${process.env.GATSBY_GOOGLE_PK}&libraries=places`,
    global: "google.maps.places.Autocomplete",
  });
  const inputRef = useRef();
  const autocompleteRef = useRef();
  const [dirty, setDirty] = useState(false);
  const [typedValue, setTypedValue] = useState();
  useEffect(() => {
    if (!Autocomplete) return undefined;
    autocompleteRef.current =
      autocompleteRef.current ||
      new Autocomplete(inputRef.current.input, {
        fields: ["geometry", "name", "address_components"],
      });
    const listener = autocompleteRef.current.addListener(
      "place_changed",
      () => {
        const place = autocompleteRef.current.getPlace();
        setDirty(false);
        onChange({
          target: {
            value: {
              coordinates: place.geometry.viewport.toJSON(),
              name: place.name,
              country: (
                place.address_components.find(
                  ({ types }) => types.indexOf("country") !== -1
                ) || { short_name: "XX" }
              ).short_name,
            },
          },
        });
      }
    );
    return () => {
      window.google.maps.event.removeListener(listener);
    };
  }, [Autocomplete, onChange]);

  return (
    <Input
      ref={inputRef}
      value={dirty ? typedValue : name}
      onBlur={() => setDirty(false)}
      onChange={({ target: { value } }) => {
        setDirty(true);
        setTypedValue(value);
      }}
      {...props}
    />
  );
};
