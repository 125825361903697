import { get } from "lodash";
import { useRef, useState } from "react";

const useScript = ({ src, global }) => {
  const [, rerender] = useState();
  const loading = useRef(false);
  if (typeof window === "undefined") return undefined; // for SSR
  if (!get(window, global) && !loading.current) {
    loading.current = true;
    const tag =
      document.querySelector(`script[src="${src}"]`) ||
      document.head.appendChild(
        Object.assign(document.createElement("script"), { src })
      );
    const { onload } = tag;
    tag.onload = () => {
      if (onload) onload();
      rerender(true);
    };
  }
  return get(window, global);
};

export default useScript;
